@import '../index.scss';


.adding-modal-sheet {
    &__textarea {
        width: 100%;
        flex-grow: 1;
        padding: $p;
        font-size: 1rem;
        line-height: 1.4rem;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
        border: 0;
        flex-grow: 1;
    }

    .sheet__content {
        display: flex;
        flex-direction: column;
    }
}
