@import '../index.scss';


.nav {
    @include fancy;
    display: flex;
    color: $primary-text;
    flex-direction: column;
    border: 1px solid $primary !important;
    box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.8) !important;

    input {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        height: 3rem;
        flex-shrink: 0;
    }

    &__footer {
        display: flex;
        gap: $p;
        padding: $p;
        flex-wrap: wrap;

        &__link {
            text-decoration: none;
            color: $primary-text;

            &:hover {
                color: $active;
            }
        }
    }
}

.nav-section {
    &__header {
        padding: $p;
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: $p;
        text-transform: capitalize;

        &:hover {
            background-color: $active-shover;
            color: $on-content-color;
        }

        &--active {
            background-color: $active;
            color: $on-primary;
        }

        &--active:hover {
            background-color: $active-hover;
            color: $on-primary;
        }
    }

    &__saved-queries {
        display: flex;
        gap: $p;
        flex-wrap: wrap;
        padding: $p;
        padding-left: 5 * $p;
    }
}

.spinning-stuff {
    &s {
        display: flex;
        flex-direction: column;
        padding: $p;
        gap: $p;
    }

    background-color: $bg;
    @include fancy;
    padding: $p;

    &__header {
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .icon-button {
            width: 1rem;
            height: 1rem;
        }
    }
}