@import '../index.scss';

.producer-sheet {
    padding: $p;
}

.producer-view {
    display: flex;
    flex-direction: column;
    border-top-left-radius: 0.4em;
    border-top-right-radius: 0.4em;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    flex-shrink: 1;

    .producer-data {
        padding: 0;
        display: flex;
    }

    &__title {
        padding: 0;
        margin: 0;
        padding: 0;
        padding-top: $p;
        display: flex;
        flex-direction: row;
        font-size: 1.5rem;
        align-items: flex-start;

        &__text {
            flex-grow: 1;
        }

        &--p {
            padding-bottom: $p;
        }
    }

    &__top-bar {
        padding: $p2;

        &__button {
            border: 0;
            background: transparent;
            transition: all 0.2s ease-out;
            cursor: pointer;

            &:hover {
                background: $active;
                border-radius: 999999px;
            }
        }
    }

    &__note-c {
        @include card;
        margin-bottom: $p;
    }

    &__data {
        padding: 0;
    }

    &__content {
        flex-shrink: 1;
        overflow: auto;

        .products-list {
            background-color: $bg;
            gap: $p;
            display: flex;
            flex-direction: column;

            &__item {
                background-color: $content-color;

                @include selectable;
            }
        }
    }

    &__bottom-bar {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: $p2;
        // height: 0;
        overflow: hidden;
        transition: all 0.2s ease-out;
        height: 0;
        flex-shrink: 0;
        flex-grow: 0;
        flex-basis: 0;
        border-top: 0 solid $bg;

        &--open {
            flex-basis: 3rem;
            border-top: 1px solid $bg;
        }
    }

    &__note {
        margin: 0;
        line-height: 1.4rem;
    }

    &__resources {
        display: flex;
        align-items: center;
        width: 100%;
        overflow: auto;
        // background-color: $bg;
        height: 10rem;
        overflow-y: hidden;
    }

    &__resource {
        transition: opacity 0.2s ease-in-out;
        &:hover {
            opacity: 0.9;
        }

        &__image {
            max-height: 10rem;
        }
    }
}



.list-sheet {
    .products-list {
        grid-template-columns: 1fr;
    }
}

.ratings-list {
    list-style: none;
    padding: $p2 0;
    margin: 0;
    display: inline-flex;
    gap: $p2;
    flex-wrap: wrap;
    align-items: center;
}


.locate-modal__results {
    padding: 0;
    margin: 0;
    list-style: none;

    &__item {
        padding: $p;
        cursor: pointer;

        &:hover {
            background-color: rgba($active, 0.5);
        }

        &__name {
            font-weight: 700;
        }

        &__merge {
            display: block;
            background-color: $active;
            color: $content-color;
            padding: $p;
            margin: $p 0;
            @include fancy;
        }

        &__address {
            font-size: 0.9rem;
        }

        &__sub {
            font-size: 0.9rem;
            color: gray;
        }
    }
}

.producer-rating__item {
    display: flex;
    align-items: center;
    gap: $p;
}

.producer-view__locating-sheet {
    &__top-bar {
        display: flex;
        justify-content: flex-end;
        padding-bottom: $p;
    }
}

.sheet.producer-view__locating-sheet {
    @media (min-width: 64rem) {
        right: $p;
        left: auto;
    }
    @media (min-width: 97rem) {
        left: 65.5rem;
        right: auto;
    }
}

.producer-view__product-sheet {
    background-color: red;
    position: absolute;
    z-index: -1;

    &.sheet--open {
        z-index: 1;
    }
}