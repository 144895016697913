@import '../index.scss';


.products-page {
    overflow: auto;
    height: 100vh;
    background-color: $bg;


    &__saved-queries-container {
        padding: $p;
        display: flex;
        gap: $p2;
        align-items: center;
    }

    &__sort-container,
    &__filter-container {
        padding: $p;
    }

    &__list {
        list-style: none;
        padding: 0;
        margin: 0;
        padding: $p;
        gap: $p;
        display: flex;
        flex-direction: column;

        &__item {
            border-bottom: 1px solid $bg;
            @include fancy;
            background-color: $content-color;

            &:last-child {
                border-bottom: 0;
            }
        }
    }

    &__product-view {
        padding: $p;
        transition: all 0.2s ease-out;
        cursor: pointer;
        position: relative;

        &__inner {
            display: flex;
        }

        &__main {
            flex-grow: 1;
        }

        &__side {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: $p;
            gap: $p2;
            flex-wrap: wrap;
        }

        &__producer {
            &__link {
                text-decoration: none;
                font-size: 0.8em;
                color: $primary-text;
                font-weight: 500;
            }
        }

        &__title {
            font-size: 1.2em;
            padding: 0;
            margin: 0;
        }

        &__tags {
            display: flex;
            gap: $p2;
            padding: $p2;
            flex-wrap: wrap;
        }

        &__ratings {
            display: flex;
            gap: $p2;
        }

        &__fields {
            padding: $p 0;
            margin: 0 -$p;
        }

        &__resources {
            margin-left: -$p;
            margin-right: -$p;
            width: calc(100% + 2 * #{$p});
        }

        &:hover {
            background-color: $active-shover;
        }
    }
}


.M {
    width: 2rem;
    height: 2rem;
    background-color: transparent;
    border: solid 0.3rem silver;
    border-radius: 99999999px;
    display: inline-block;
    box-sizing: border-box;
    transition: $t;
    position: relative;

    &--have {
        border-color: orange;

        &::after {
            transition: $t;
            display: inline-block;
            content: ' ';
            width: 1rem;
            height: 0.3rem;
            background-color: orange;
            position: absolute;
            top: 50%;
            left: 50%;
            border-radius: 999999px;
            transform: translate(-50%, -50%);
            transition: $t;
        }
    }

    &--done {
        border-color: $primary;

        &::after {
            transition: $t;
            display: inline-block;
            content: ' ';
            width: 1rem;
            height: 1rem;
            background-color: $primary;
            position: absolute;
            top: 50%;
            left: 50%;
            border-radius: 999999px;
            transform: translate(-50%, -50%);
            transition: $t;
        }
    }

    &:hover {
        border-color: $active;
        cursor: pointer;
        &::after {
            background-color: $active;
        }
    }
}
