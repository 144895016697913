@import '../index.scss';


.selection-shelf {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: stretch;
    flex-direction: row;
    border-top-left-radius: $p;
    border-bottom-left-radius: $p;
    overflow: hidden;
    max-height: 80vh;
    min-height: 5rem;
    // right: 0;
    z-index: 9999999999;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
    // left: 0;
    transition: $t;
    right: 0;
    transform: translate(calc(100% - 2rem), -50%);

    .button {
        background-color: $content-color;
        color: $primary-text;

        &:hover {
            background-color: $active-sshover;
        }
    }

    &__opener {
        width: 4 * $p;
        flex-shrink: 0;
        background-color: $active;
        transition: $t;

        &:hover {
            background-color: $active-hover;
            cursor: pointer;
        }
    }

    &__content {
        background-color: $primary;
        gap: $p;
        padding: $p;
        transition: $t;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        flex-shrink: 1;
        overflow-y: auto;

        &__inner {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
        }
    }

    &--open {
        transform: translate(0, -50%);
        .selection-shelf__content {
        }
    }

    &__producers-list {
        padding: 0;
        margin: 0;
        @include card;
        list-style: none;
    }

    &__list {
        padding: 0;
        list-style: none;
        margin: 0;
        display: flex;
        flex-direction: column;
        gap: $p;

        &__item {
            display: flex;
            // display: grid;
            // grid-template-columns: 1fr 1fr;
            align-items: flex-start;
            gap: $p;
            row-gap: $p;
            @include card;

            &__main {
                font-weight: 700;
            }

            &__others {
                display: flex;
                flex-direction: column;
            }
        }
    }

    &__footer {
        display: flex;
        align-items: center;
        gap: $p / 2;
    }
}
