@import '../index.scss';

.producer-editor-modal {
    height: calc(100% - 2 * $p);
    background-color: $bg;
    padding: $p;
    box-sizing: border-box;
    width: 64rem;
    display: flex;
    flex-direction: column;

    &__top-bar {
        padding-bottom: $p;
        display: flex;
        align-items: center;
        align-items: center;
        justify-content: space-between;
        gap: $p;

        h2 {
            margin: 0;
            text-overflow: ellipsis;
            display: inline-flex;
            align-items: center;
            gap: $p;
            flex-shrink: 1;
            // font-size: 0.9rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            font-size: 0.9rem;

            @media screen and (min-width: 400px) {
                font-size: 1rem;
            }

            @media screen and (min-width: 600px) {
                font-size: 1.5rem;
            }

            span {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                min-width: 2rem;
                flex-shrink: 0;
            }
            span:last-child {
                flex-shrink: 1;
            }
        }
    }

    &__top-bar-buttons {
        display: flex;
        align-items: center;
        gap: $p;

        .icon-button {
            height: 2.25rem !important;
            width: 2.25rem !important;
        }
    }

    &__content {
        flex-grow: 1;
        overflow-y: auto;

        .editor {
            position: absolute;
            top: 3rem;
            right: 0;
            bottom: 0;
            left: 0;
            padding: $p;
            overflow-y: auto;
            background-color: $bg;
        }
    }

    &__b {
        display: inline-block;
        color: grey;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:hover {
            color: $primary-text;
        }
    }

    &__b:last-child {
        color: $on-content-color;

        &:hover {
            color: $primary-text;
        }
    }
}
