@import '../index.scss';


.sort-builder {
    display: flex;
    flex-direction: column;
    gap: $p;

    &__bottom-bar {
        padding: $p 0;
    }

    &__top {
        display: flex;
        align-items: center;
        gap: $p2;
    }
}

.sort-item {
    display: flex;
    align-items: center;
    gap: $p2;
    flex-grow: 1;

    &__operand {
        flex-grow: 1;
    }

    .button:first-child {
        flex-grow: 1;
        justify-content: flex-start;
    }
}