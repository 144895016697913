@import './index.scss';

.page-login {
    &__inner {
        display: flex;
        flex-direction: column;
        gap: $p;
        min-width: 16rem;

        align-items: stretch;
    }
}
