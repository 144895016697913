@import '../index.scss';

.products-editor-modal {
    &__tags {
        display: flex;
        gap: $p2;
    }

    &__bulk-editor {
        display: flex;
        flex-direction: column;
        gap: $p2;

        &__main {
            display: flex;
            gap: $p;
            align-items: center;

            input {
                flex-grow: 1;
            }
        }
    }

    &__item {
        @include card;
    }
}

.products-editor-content {
    display: flex;
    flex-direction: column;
    gap: $p;
}
