@import '../index.scss';

.rating {
}


.rating-editor {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    overflow: hidden;
    gap: 1px;

    input {
        border-radius: 0;
    }

    &__top {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: stretch;
        height: 3rem;
        gap: $p2;
        padding-right: $p2;
        background-color: $input-color;
        box-sizing: border-box;
    }

    &__manual {
        display: flex;
        width: 100%;
        overflow: hidden;
        gap: $p2;
        padding-bottom: $p3;

        input {
            flex-shrink: 1;
            flex-grow: 1;
            flex-basis: 0;
            width: 0;
        }
    }

    &__quick {
        gap: 0.1rem;
        padding: $p2;
        display: flex;
        flex-direction: column;
        gap: $p;
        max-width: 42rem;

        .button {
            background-color: $content-color;
            color: $on-content-color;

            &:hover {
                background-color: $active-shover;
            }
        }

        &__source {
            display: block;
            font-weight: 700;
            font-size: 0.8rem;
        }

        &__ratings {
            display: flex;
            flex-wrap: wrap;
            gap: $p3;
        }
    }

    .icon-button {
        width: 1.6rem;
        height: 1.6rem;
        padding: $p2;
    }

}

.rating {
    @include size-mins;
    display: inline-flex;
    border-radius: 0.6rem;
    padding: 0 $p;
    font-size: 0.8em;
    // border: 1px solid $primary-text;
    height: 2rem;
    box-sizing: border-box;
    align-items: center;
    line-height: 1rem;
    font-feature-settings: "tnum";
    border-radius: 9999px;
    font-variant-numeric: tabular-nums;
    overflow: hidden;
    background-color: $primary;
    color: $on-primary;

    & > * {
        pointer-events: none;
    }

    &[role='button'] {
        @include clickable;
    }

    &--clickable {
        @include clickable;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:hover {
            background-color: $active-shover;
            color: $on-content-color;
        }
    }

    &__canonical {
        padding-right: $p2;
    }

    &__normalized {
        padding-left: $p2;
        // border-left: 1px solid #663299;
        height: 100%;
        display: flex;
        align-items: center;
        background-color: $content-color;
        color: $on-content-color;
        opacity: 0.5;
        margin-right: calc(#{-$p} - 1px);
        padding-right: $p;
    }

    &--source-vinous {
        background-color: #ba3c40;
        color: white;
    }

    &--source-dw {
        background-color: #960a2c;
        color: white;
    }

    &--source-js {
        background-color: #000000;
        color: #f8f8f8;
    }

    &--source-gr {
        background-color: #b60217;
        color: white;
    }

    &--source-veronelli {
        background-color: #b3904e;
        color: white;
    }

    &--source-ais {
        background-color: #bf9100;
        // color: white;
    }

    &--source-michelin {
        background-color: #bb032a;
        color: $content-color;
    }
}