@import '../index.scss';



.filter-container {
    padding: $p;
    background-color: $content-color;
    @include fancy;

    &__label {
        text-transform: uppercase;
        font-size: 0.8rem;
        opacity: 0.8;
    }
}

.filter-builder {
    &__bottom-bar {
        padding: $p 0;
    }

    &__top {
        display: flex;
        gap: $p;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    display: flex;
    flex-direction: column;
    gap: $p;
}

.filter-item {
    padding: 0;
    display: flex;
    align-items: center;
    gap: $p2;
    max-width: 100%;
    box-sizing: border-box;

    &__inner {
        flex-grow: 1;
        display: flex;
        gap: $p2;
        align-items: center;
        flex-wrap: wrap;

        .filter-builder {
            flex-grow: 1;
            background-color: $bg;
            @include fancy;
            padding: $p;
        }
    }

    &__operand {
        flex-grow: 1;
        flex-shrink: 1;

        input {
            min-width: 0 !important;
            flex-basis: 0;

        }
    }

    .button {
        flex-shrink: 0;
    }
}

