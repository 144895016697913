@import './index.scss';

.page-product-preset {
    padding: $p;

    &__tag-list {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            display: flex;
            align-items: center;
            gap: $p2;
        }
    }

    &__tag {
        flex-grow: 1;
    }
}
