@import './index.scss';


$w: 30rem;
$x: 3;

.merger {
    position: fixed;
    top: 50%;
    right: -$w;
    width: $w;
    display: flex;
    align-items: stretch;
    @include fancy;
    overflow: hidden;
    transition: all ease-in-out 0.2s;
    background-color: $content-color;

    &--visible {
        right: -$w + $x * $p;
    }

    &:hover {
        right: -$w + ($x + 0.5) * $p;
    }

    &--open {
        right: 0;

        &:hover {
            right: 0;
        }
    }

    &__expander {
        transition: all ease-in-out 0.2s;
        display: block;
        background-color: $active;
        min-height: 5rem;
        width: $x * $p;
        cursor: pointer;
        &:hover {
            background-color: $active-hover;
        }
    }

    &__content {
        display: flex;
        align-items: center;
        padding: $p;
        opacity: 0;
        flex-grow: 1;
        gap: $p;

        &--visible {
            opacity: 1;
        }
    }

    &__editor {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: $p;

        input {
            width: 100%;
        }
    }
}
