@import '../index.scss';

.query {
    display: flex;
    flex-direction: column;
    padding-bottom: $p;
    gap: $p;

    &__buttons {
        display: flex;
        gap: $p2;
        align-items: center;
        flex-wrap: wrap;
    }

    &__item {
        width: 100%;
        box-sizing: border-box;
        position: relative;

        &__inner {
            border: 1px solid silver;
            padding: 0.4rem;
            box-sizing: border-box;
            width: 100%;
            border-radius: 0.4rem;
            text-align: center;
        }

        &__name {
            text-transform: uppercase;
            color: silver;
        }

        textarea {
            width: calc(100%);
            font-size: 1rem;
        }

        .icon-button {
            position: absolute;
            right: 0.5rem;
            height: 1rem;
            width: 1rem;
            border-bottom: 0;
            box-shadow: none;
            top: 50%;
            // translate: t;
            transform: translateY(-50%);
            overflow: hidden;
        }
    }
}

.paging {

}
