@import '../index.scss';

.query-builder {
    padding: $p;

    &__header {
        padding: 0;
        margin: 0;
        text-transform: uppercase;
        color: darkgrey;
    }

    &__line {
        display: flex;
        gap: $p2;
    }
}
