@import '../index.scss';


.load-more {
    @include card;
    @include ccard;
    display: block;
    box-sizing: border-box;
    width: 100%;
    border: 0;
    text-transform: uppercase;
    font-size: 0.8rem;
    margin-bottom: $p;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
    color: $primary-text;
    border: 0;
    box-shadow: none;
}
