@import '../index.scss';


.producers-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: $p;
    padding-top: 0;

    &__item {


        transition: all 0.2s ease-out;
        border-left: 0px solid white;

        border-bottom: 1px solid $bg;

        @include card;
        @include ccard;
        @include selectable;

        .producer-view__data {
            padding: 0;
        }
        cursor: pointer;

        &:hover {
            background-color: $active-shover;
        }

        &:last-child {
            border-bottom: 0;
        }

    }

    &__producer-name {
        font-size: 1.2em;
        padding: 0;
        margin: 0;
    }


    &__producer__note {
        padding: 0;
        margin: 0;
        padding-bottom: $p;
    }

    &__producer-info {
        font-size: 0.8em;
    }

    &__producer-buttons {
        padding: 0.4rem 0;
        display: flex;
        flex-wrap: wrap;
        gap: $p2;
        align-items: flex-start;
        flex-direction: column;
    }

    &__producer-inner {
        display: flex;
        align-items: center;
        width: 100%;
        flex-grow: 1;
        box-sizing: border-box;

        &__main {
            flex-grow: 1;
        }

        &__side {
            padding: 0.4rem;
            display: flex;
            flex-direction: column;
            gap: $p2;
            align-items: flex-end;
        }
    }
}