@import '../index.scss';

.spinnger {
    @include size-sets;
    background-color: $primary;
    transition: all 0.2s ease-in-out;
    color: $content-color;
    box-sizing: border-box;

    &--danger {
        background-color: $danger;
    }

    &__opener {
        width: 2rem;
        height: 2rem;
        border: 0;
        background-color: $primary;
        transition: all 0.2s ease-in-out;
        @include fancy;
        display: flex;
        align-items: center;
        justify-content: center;

        * {
            transition: all 0.2s ease-in-out;
            opacity: 0;
        }
    }
}

.spinner-centered {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(white, 0.5);
}


.spinner {
    display: inline-block;
    position: relative;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;

    &:after {
        content: " ";
        display: block;
        border-radius: 50%;
        width: 1.4rem;
        height: 1.4rem;
        margin: 0;
        position: absolute;
        top: 0.3rem;
        box-sizing: border-box;
        border: 0.3rem solid #FFF;
        border-color: $active $active $active rgba(#FFF, 1);
        animation: lds-hourglass 1s linear infinite;
    }

    &--size-1000 {
        width: 5rem;
        height: 5rem;

        &:after {
            width: 4.4rem;
            height: 4.4rem;
            border-width: 1rem;
        }
    }

    &--primary {
        &:after {
            border-color: $active $primary $primary $primary;
        }
    }
}

@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
