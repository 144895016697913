@import '../index.scss';


.producers-product-list__best-dates {
    color: rgb(150, 150, 150);
    font-size: 0.8rem;
}

.producers-product-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: 100%;
    gap: $p;

    &__item {
        @include fancy;
        background-color: transparent;
        overflow: hidden;

        &__header {
            display: flex;
            align-items: center;
            gap: $p;
            padding: $p;
            font-weight: 700;
            transition: $t;

            background-color: $bg;

            &[role="button"] {
                cursor: pointer;
                background-color: $content-color;
            }

            &:hover {
                background-color: $active-shover;
            }

            &:has([role='button']:hover) {
                background-color: $content-color;
            }
        }

        &__aside {
            display: flex;
            flex-grow: 1;
            gap: $p / 2;
            align-items: center;
            justify-content: flex-end;
        }
    }

    &__years-list {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        gap: 1px;
        margin-top: 1px;
        border-bottom-left-radius: $p;
        border-bottom-right-radius: $p;
        overflow: hidden;

        &__item {
            background-color: $content-color;
            padding: $p2 $p;
            display: flex;
            align-items: center;
            gap: $p;
            transition: $t;
            position: relative;

            &__header {
                font-feature-settings: "tnum";
                font-variant-numeric: tabular-nums;
            }

            & > * {
                pointer-events: none;
            }

            &:hover {
                background-color: $active-shover;
                cursor: pointer;
            }
        }
    }
}

.producers-product-list__item__header:not([role="button"]) + .producers-product-list__years-list {
    border-top-left-radius: $p;
    border-top-right-radius: $p;
}

.list-sheet,
.page-products__content {
    .producers-product-list__item__header,
    .producers-product-list__years-list__item {
        background-color: $bg;
    }
    .producers-product-list__item__header:hover,
    .producers-product-list__years-list__item:hover {
        background-color: $active-shover;
    }
}


.list-sheet,
.producer-sheet {
    .producers-product-list {
        grid-template-columns: 1fr !important;
    }
}

.producer-sheet {
    .producers-product-list__item__header,
    .producers-product-list__years-list__item {
        background-color: $bg;
        &[role="button"] {
            background-color: $content-color;
        }

    }

    .producers-product-list__years-list__item:hover {
        background-color: $active-shover;
    }
}
