@import '../index.scss';

.product-editor-mini,
.product-editor {
    display: flex;
    flex-direction: column;
    gap: $p;
    position: relative;
}


.duplicates-warning {
    padding: $p;
    background-color: $bg;
    display: flex;
    gap: $p;
    flex-wrap: wrap;

    h5 {
        padding: 0;
        margin: 0;
    }

    &__i {
        @include card;
        @include ccard;
    }
}
