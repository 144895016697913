@import '../index.scss';


.product-rating-filter-component {
    display: flex;
    align-items: center;
    gap: $p;

    input {
        flex-grow: 1;
    }
}
