@import './index.scss';

.page-multi-add {
    display: flex;
    flex-direction: column;
    gap: $p;

    &__adder {
        display: flex;
        gap: $p2;
        flex-direction: column;
        padding-bottom: $p2;
        align-items: flex-end;
        justify-content: flex-end;
    }

    &__bar {
        display: flex;
        gap: $p2;
        width: 100%;
    }

    &__actions {
        display: flex;
        gap: $p2;
        padding-bottom: $p2;
        flex-wrap: wrap;
        align-items: center;

        input {
            flex-grow: 1;
            flex-shrink: 1;
            width: 0;
            min-width: 5rem;
        }
    }

    &__tags {
        display: flex;
        gap: $p2;
    }

    &__producer {
        display: flex;
        flex-direction: column;
        gap: $p2;

        &__error {
            padding: $p;
            background-color: $danger;
            @include fancy;
        }

        &__bottom {
            display: flex;
            align-items: center;
            gap: $p2;
        }

        &__address {
            padding: $p;
            font-size: 0.8rem;
            flex-grow: 1;
        }
    }

    &__spinner-container {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;

        .spinner:after {
            width: 5rem;
            height: 5rem;
        }
    }
}
