@import '../index.scss';

$tt: all 10s linear;

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(black, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    // transition: $tt;

    @media (prefers-color-scheme: dark) {
        background-color: rgba(black, 0.9);
    }

    &--closed {
        pointer-events: none;
        opacity: 0;
    }

    &__inner {
        background-color: $bg;
        max-width: calc(100% - 1rem);
        max-height: calc(100% - 1rem);
        border-radius: 0.4rem;
        overflow: hidden;
        position: relative;
        display: flex;
        flex-direction: column;
        // transition: $tt;

        &--transparent {
            background-color: transparent;
        }

        &--padded {
            gap: $p;
            padding: $p;
        }
    }

    &__padding {
        padding: $p;
    }
}
