@import '../index.scss';


.producer-editor {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: $p;

    &__x {
        display: flex;
        flex-direction: column;
        gap: $p;
    }
}

.new-item-card {
    @include card;
    @include ccard;
    display: flex;
    cursor: pointer;
    transition: all 0.2ss ease-in-out;
    align-items: center;
    @include card;
    @include ccard;
    margin-bottom: $p;
    gap: $p;

    &:hover {
        background-color: $active-shover;
    }

    &__label {
        text-transform: uppercase;
        color: darkgray;
        font-size: 0.8rem;
    }
}


.new-product-from-template {
    @include card;
    @include ccard;
    display: flex;
    align-items: center;
    gap: $p;

    &__main {
        flex-grow: 1;
    }

    &__tags {
        display: flex;
        gap: $p2;
    }

    &__tag {
        border-radius: 0.4rem;
        font-size: 0.8rem;
        border: 1px solid $primary;
        padding: $p / 2;;
    }
}