@import '../index.scss';

.resources {
    height: 8rem;
    overflow: hidden;
    @include fancy;
    display: flex;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    background-color: $content-color;
    box-sizing: border-box;
    line-height: 0;
}

.resource {
    display: inline-block;
    height: 8rem;
    transition: all 0.2s ease-in-out;
    box-shadow: none;
    border: 0;
    box-sizing: border-box;
    line-height: 0;
    background-color: $content-color;
    cursor: pointer;
    position: relative;

    * {
        pointer-events: none;
    }

    img {
        max-height: 100%;
    }

    &:hover {
        img {
            opacity: 0.7;
        }
    }

    &__m {
        height: calc(100vh - 13.5rem);
        max-width: calc(100vw - 1rem);
        background-color: $content-color;
        text-align: center;
        position: relative;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        flex-shrink: 1;
        justify-content: center;
        align-items: center;
        border-top-left-radius: $p;
        border-top-right-radius: $p;

        &__image {
            max-height: 100%;
            max-width: 100%;
            border-top-left-radius: $p;
            border-top-right-radius: $p;
            overflow: hidden;

        }
        top: 0 !important;
        box-shadow: none !important;
    }
}

.resources-modal {
    display: flex;
    flex-direction: column;
    width: 99vw;
    overflow: hidden;
    align-items: center;
    height: calc(100vh - 1.5rem);
    width: 100%;
    overflow: hidden;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    padding: $p;
    box-sizing: border-box;
    // flex-grow: 1;

    &__inner {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        position: relative;
        flex-grow: 1;
        flex-shrink: 1;
        overflow: hidden;
        justify-content: flex-end;
    }

    &__previews {
        margin-top: $p;
        display: flex;
        flex-shrink: 0;
        @include fancy;
        overflow-x: auto;
        overflow-y: hidden;
        transition: $t;
        justify-content: center;
        max-width: calc(100vw - 1rem);
        height: 7rem;
        padding: 0;
        margin: 0;
        margin-top: $p;

        @media screen and (max-width: 500px) {
            height: 4rem;

            .resource {
                height: 4rem;
            }
        }

        & > {
            *:first-child {
                border-top-left-radius: $p;
                border-bottom-left-radius: $p;
                overflow: hidden;
            }
            *:last-child {
                border-top-right-radius: $p;
                border-bottom-right-radius: $p;
                overflow: hidden;
            }
        }
    }

    &__main {
        flex-grow: 1;
        flex-shrink: 1;
        overflow: hidden;
        @include fancy;
        max-height: 100%;
        // width: 100vw;
        overflow: hidden;
        border-radius: 0;
    }

    &__close-button {
        position: absolute !important;
        z-index: 10;
        right: $p;
        top: $p !important;

        &:hover {
            top: $p !important;
        }
    }
}


.resource-buttons {
    background-color: $content-color;
    padding: $p;
    display: flex;
    align-items: center;
    flex-shrink: 0;

    overflow: hidden;
    gap: $p2;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom-left-radius: $p;
    border-bottom-right-radius: $p;

    &__spacer {
        width: 2rem;

        &--grow {
            flex-grow: 1;
        }
    }

}