@import '../index.scss';


.field-select {
    gap: $p2;

    &__part {
        opacity: 0.7;

        &:last-child {
            font-weight: 700;
            opacity: 1;

            &::before {
                content: ' ';
            }
        }
    }
}
