.modal-sheet {
    transition: opacity 0.2s ease-in-out;

    &--open {
        opacity: 0;
    }

    &--visible-open {
        opacity: 1;
    }

    &--closed {
        opacity: 0;
    }

}
