@import '../index.scss';




input,
textarea {
    background-color: #e4e7e8;
    border-radius: 0.4rem;
    border: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    box-sizing: border-box;
    padding: $p;
    font-size: 1rem;
    height: 2rem;
}

.editor {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    position: relative;
    gap: $p;

    input,
    textarea {
        max-width: 100%;
    }

    &__slug {
        color: grey;
        font-size: 0.7rem;
        margin-bottom: -$p;
    }

    &__ee {
        @include card;
        display: flex;
        flex-direction: column;
        gap: $p;
    }

    &__e {
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        position: relative;
        background-color: $content-color;
        @include card;
        display: flex;
        flex-direction: column;
        gap: $p;


        &:hover {
            background-color: $active-shover;

            &:has([role="button"]:hover) {
                background-color: $content-color;
            }
        }

        border-left: 0 solid white;

        &--selectable {
            border-left: $p solid $active-shover;

            * {
                pointer-events: none;
            }
        }

        &--selected {
            border-left: $p solid $active;
        }

        &--collapsed {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        &__title {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
    }

    &__s {
        display: flex;
        flex-wrap: wrap;
        gap: $p2;
        flex-direction: column;
        align-items: flex-start;

        @media (min-width: 1000px) {
            flex-direction: row;
            align-items: center;
        }

        .button {
            height: 2rem;
        }

        .tag {
            height: 2rem;
        }

        .rating {
            height: 2rem;
            box-sizing: border-box;
            align-items: center;
            display: inline-flex;
            border-radius: 99999px;
        }
    }

    &__i {
        display: flex;
        align-items: center;
        gap: $p2;

        &__m {
            flex-grow: 1;
            flex-shrink: 1;

            input {
                width: 100%;
            }
        }

        .button {
            height: 2rem;
        }
    }

    &__n {
        border: 0 !important;
        margin-left: -$p;
        word-break: break-all;
    }

    &__r {
        height: 15rem;
        display: flex;
        align-items: center;
        overflow-x: auto;
        overflow-y: hidden;
        @include fancy;

        img {
            max-height: 15rem;
        }
    }
}
