$p: 0.5rem;
$p2: 0.2rem;
$p3: 0.1rem;
$size: 2rem;
$touch-size: 44px;

:root {
    --bg: #f0f0f3;
    --content-color: white;
    --on-content: black;
    --primary: #663299;
    --primary-text: #663299;
    --input-color: #e4e7e8;
    --on-input-color: black;
    --active: #cc48de;
    --on-primary: white;
    --xc: white;
    --danger: red;
}

@media (prefers-color-scheme: dark) {
    :root {
        --bg: #211d25;
        --content-color: #353239;
        --on-content: white;
        --active: #9735a5;
        --primary: #663299;
        --primary-text: #cc48de;
        --input-color: #111111;
        --on-input-color: white;
        --on-primary: white;
        --xc: black;
        --danger: red;
    }

    body {
        color: rgb(240, 240, 255);
    }
}

$danger: var(--danger);
$bg: var(--bg);
$input-color: var(--input-color);
$on-input-color: var(--on-input-color);
$content-color: var(--content-color);
$on-content-color: var(--on-content);
$primary: var(--primary);
$primary-text: var(--primary-text);
$primary-hover: #a953ff;
$active: var(--active);
$active-hover: #e953ff;
$active-shover: rgba(#e953ff, 0.2);
$active-sshover: lighten(#e953ff, 20);
$t: all ease-in-out 0.2s;
$on-primary: var(--on-primary);
$xc: var(--xc);


@mixin clickable {
    box-shadow: 0 $p3 0 0 $active;
    position: relative;
    transition: $t;
    top: 0;

    &:hover {
        // top: -$p3;
        box-shadow: 0 $p3 0 0 #cc48de;
    }

    &:active {
        // top: $p3;
        box-shadow: 0 0 0 0 #cc48de;
    }
}

@mixin fancy {
    border-radius: $p;
}


@mixin top-fancy {
    border-top-left-radius: $p;
    border-top-right-radius: $p;
}

@mixin size-mins {
    min-height: $size;
    min-width: $size;

    @media (hover: none) {
        min-height: $touch-size;
        min-width: $touch-size;
    }
}

@mixin size-sets {
    height: $size;
    width: $size;

    @media (hover: none) {
        height: $touch-size;
        width: $touch-size;
    }
}

@mixin selectable {
    &--selectable {
        border-left: $p solid $primary;
        cursor: pointer;

        &:hover {
            background-color: $active-shover;
            border-left-color: $primary-hover;
        }
    }

    &--selected {
        border-left: 1rem solid $active;

        &:hover {
            border-left-color: $active-hover;
        }
    }
}

@mixin card {
    @include fancy;
    background-color: $content-color;
    padding: $p;
    border: 0;
    // border-bottom: 2px solid $active-hover !important;
}

@mixin carditem {
    &:first-child {
        border-top-left-radius: $p;
        border-top-right-radius: $p;

    }
    &:last-child {
        border-bottom-left-radius: $p;
        border-bottom-right-radius: $p;
    }
}

@mixin ccard {
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
        background-color: $active-shover;
    }
}

@mixin pcard {
    display: flex;
    flex-direction: column;
    gap: $p;
}
