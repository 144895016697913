@import '../index.scss';


.popup-select {
    position: fixed;
    top: 0;
    padding: 0;
    margin: 0;
    transition: opacity 0.2s ease-out;
    opacity: 0;

    background-color: $content-color;
    color: $on-content-color;
    border: 1px solid rgba(0, 0, 0, 0.4);
    box-shadow: 0 0 $p 0 rgba(0, 0, 0, 0.4);
    box-sizing: border-box;
    border-radius: $p;

    &__query {
        width: 100%;
        box-sizing: border-box;
        border-radius: 0;
        border: 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        height: 2rem;
        padding: 0 $p;
        font-size: 1rem;
    }

    &__list {
        list-style: none;
        padding: 0;
        margin: 0;

        hr {
            opacity: 0.2;
            padding: 0;
            margin: 0.1rem;
        }
    }

    &__item {
        padding: $p;
        transition: all 0.2s ease-out;
        cursor: pointer;
        width: 100%;
        box-sizing: border-box;

        &:hover {
            background-color: $active-shover;
        }

        @media screen and (max-width: 1000px) {
            width: calc(100vw - 1rem);
            min-height: 3rem;
            display: flex;
            align-items: center;
        }
    }
}
