@import './index.scss';


.app__master {
    .content {
        flex-grow: 1;
        flex-shrink: 1;
        overflow: auto;
        width: 100%;
        overflow-x: hidden;

    }

    &__buttons-container {
        padding: 0 calc($p + 1px);
        display: flex;
        gap: 0.2rem;
        margin-top: -3px;
    }

    &__top-bar {
        padding: $p;
        display: flex;
        gap: 0.2rem;
    }

    &__bottom-bar {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0 $p;
        gap: 0.2rem;
        overflow: hidden;
        transition: all 0.2s ease-out;
        height: 0;
        flex-shrink: 0;
        flex-grow: 0;
        flex-basis: 0;
        @include fancy;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        &--open {
            flex-basis: 3rem;
        }
    }
}


.app__map {
    background-color: lightblue;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    right: 0;
}

.map-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &__controls {
        position: absolute;
        top: calc($p + 2px + 2rem + 0.2rem);
        right: $p;

        .button {
            box-shadow: 0 2px 0 $active;
        }
    }
}

.app__master.sheet {
    top: calc(100% - 3rem);

    @media (min-width: 64rem) {
        top: $p;
    }
}

.uploading-popup {
    position: fixed;
    top: $p;
    left: $p;
    right: $p;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &__list {
        padding: 0;
        margin: 0;
        list-style: none;
        padding: $p;
        background-color: rgba($primary, 0.8);
        color: $content-color;
        pointer-events: none;
        @include fancy;
    }
}

.map {
    &__controls {
        position: absolute;
        display: flex;
        top: $p;
        right: $p;
        display: flex;
        gap: $p/2;
        flex-direction: row-reverse;

        @media screen and (min-width: 2 * 32rem + 3 * $p) {
            flex-direction: column;
        }
    }
}
.content {
    display: flex;
    flex-direction: column;
    gap: $p;
}