@import '../index.scss';


.best-dates-editor {
    &__e {
        border: 1px solid $on-content-color;
        height: 2rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 0 0.5rem;
        box-sizing: border-box;
        min-width: 2rem;
        @include fancy;
        gap: $p2;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        * {
            pointer-events: none;
        }

        &:hover {
            background-color: $active-shover;
        }
    }

    &__l {
        .icon-button {
            width: 1.6rem;
            height: 1.6rem;
            padding: $p2;
        }
    }
}
