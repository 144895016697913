@import './index.scss';

.page-trash {
    &__item {
        @include card;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
