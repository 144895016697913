@import '../index.scss';


.button {
    background-color: $primary;
    color: $on-primary;
    border: 1px solid $primary;
    border-radius: 0.4rem;
    text-decoration: none;
    padding: 0 0.4rem;
    margin: 0;
    display: inline-flex;

    @include size-mins;

    font-size: 0.8rem;
    box-sizing: border-box;
    align-items: center;
    gap: $p2;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease-out;
    text-transform: uppercase;
    font-size: 0.8rem;
    min-height: 2rem;
    position: relative;
    box-shadow: 0 $p3 0 0 $active;
    top: 0;
    overflow: hidden;
    text-overflow: ellipsis;

    &--square {
        height: 2rem;
        width: 2rem;

        @media (hover: none) {
            height: $touch-size;
            width: $touch-size;
        }
    }

    &--round {
        border-radius: 999999px;
    }

    * {
        pointer-events: none;
    }

    &:hover {
        background-color: $primary-hover;
        border-color: $primary-hover;
    }

    &--active {
        background-color: $active;

        &:hover {
            background-color: $active-hover;
            border-color: $active-hover;
        }
    }

    &--danger {
        border-color: red;
        background-color: red;
        color: $content-color;

        &:hover {
            background-color: $active;
        }
    }

    &:disabled {
        cursor: not-allowed;
        background-color: silver !important;
        border-color: silver !important;
    }
}


.icon-button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    svg {
        width: 3rem;
        height: 3rem;
    }

    @media screen and (min-width: 600px) {
        height: 2rem;
        width: 2rem;

        svg {
            width: 2rem;
            height: 2rem;
        }
    }

    .path:hover {
        fill: red;
    }

    * {
        pointer-events: none;
    }
}


.stack-buttons {
    display: flex;
    flex-direction: column;
    @include fancy;
    overflow: hidden;
    height: 2rem;

    .button {
        height: auto;
        flex-basis: 0;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        flex-shrink: 1;
        box-sizing: border-box;
        padding: 0;
        min-height: 0;

        border-radius: 0;
    }
}