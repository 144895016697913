@import '../index.scss';

.switcher {
    display: inline-flex;
    gap: 0;

    &--w100 {
        width: 100%;
    }

    &--grow {
        flex-grow: 1;
    }

    &__item {
        flex-grow: 1;
        background-color: $primary;
        color: $on-primary;
        transition: all 0.2s ease-out;
        font-size: 0.8rem;
        border-radius: 0 !important;

        &:first-child {
            border-top-left-radius: $p !important;
            border-bottom-left-radius: $p !important;
            border-left: 0;
        }

        &:last-child {
            border-top-right-radius: $p !important;
            border-bottom-right-radius: $p !important;
        }

        &:hover {
            background-color: $primary-hover;
            border-color: $primary-hover;
        }

        &--active {
            background-color: $active;

            &:hover {
                background-color: $active-hover;
                border-color: $active-hover;
            }
        }

        @media (min-width: 32rem) {
            font-size: 0.6rem;
            min-height: 1.6rem;
            min-width: 1.6rem;
        }

        &--w100 {
            width: 0;
            flex-basis: 0;
            flex-grow: 1;
            flex-shrink: 1;
        }
    }
}
