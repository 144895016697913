@import './index.scss';

.page-templates {
    padding: $p;
    background-color: $bg;
    display: flex;
    flex-direction: column;
    gap: $p;
}

.template-editor {
    display: flex;
    flex-direction: column;
    gap: $p;
    padding: $p;
    @include fancy;
    background-color: $content-color;

    &__name {
        font-weight: 700;
    }
}
