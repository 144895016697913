@import '../index.scss';


.sheet {
    @include fancy;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    position: absolute;
    top: calc(100%);
    left: 0;
    right: 0;
    background-color: $bg;
    overflow: hidden;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    transition: all 0.2s ease-out;
    bottom: 0;
    box-sizing: border-box;
    padding: $p;
    gap: $p;

    &--open {
        top: 10px;

        .sheet__indicator {
            &__icon {
                height: 1rem;
            }
            margin-bottom: -$p/2;
        }
    }

    &--no-transition {
        transition: none !important;
    }

    @media (min-width: 65.5rem) {
        top: $p;
        left: $p;
        height: calc(100% - 1rem);
        width: 32rem;
        overflow: hidden;
        @include fancy;
    }

    &__indicator {
        &__icon {
            width: 100%;
            height: 2rem;
            transition: height 0.2s ease-in-out;

            @media (min-width: 65.5rem) {
                display: none;
                margin: 0;
            }
        }
    }

    &__content {
        overflow: auto;
        @include fancy;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        flex-grow: 1;

        @media screen and (min-width: 60rem) {
            margin: 0;
            @include fancy;
        }
    }
}
