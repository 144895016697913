@import './index.scss';


.page-products {


    &__content {
        display: flex;
        flex-direction: column;
        gap: $p;
        padding: $p;
    }

    &__product {
        @include card;
        @include ccard;
        @include pcard;
        position: relative;

        h2 {
            font-size: 1.2em;
            padding: 0;
            margin: 0;
        }

        &__producer {
            display: inline-block;
            text-decoration: none;
            font-size: 0.8em;
            color: $primary-text;
            font-weight: 500;
            margin-bottom: -$p;
        }

        &--selectable {

        }
    }

    &__rater {
        display: flex;
        flex-direction: column;
        gap: $p;

        &__rater {
            display: flex;
            gap: $p2;
            align-items: center;
            font-size: 0.8rem;
            font-family: monospace;
        }
    }
}
