@import './index.scss';

$w: 32rem;


.sheet--drop {
    &:after {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: ' ';
        @include fancy;
        border: 5px dashed silver;
    }
}

.list-sheet {
    top: calc(100% - 3rem);

    &.sheet--open {
        top: $p + 2rem + $p;
    }

    &--producer-sheet-position-middle.sheet--open,
    &--producer-sheet-position-middle.sheet--closed,
    &--producer-sheet-position-open.sheet--open,
    &--producer-sheet-position-open.sheet--closed {
        top: 100%;
    }
}

.producer-sheet {
    &--producer-sheet-position-middle {
        top: 40%;
    }

    &--producer-sheet-position-open {
        top: $p + 2rem + $p;
    }

    &--product-sheet-position-open {
        top: 100%;
    }


    &__deleted-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.8);
        color: $content-color;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media screen and (min-width: 2 * $w + 3 * $p) {
    .list-sheet {
        top: $p;

        &--producer-sheet-position-middle.sheet--open,
        &--producer-sheet-position-middle.sheet--closed,
        &--producer-sheet-position-open.sheet--open,
        &--producer-sheet-position-open.sheet--closed {
            top: $p;
        }

        &--product-sheet-position-open {
            left: -$w;
        }
    }

    .producer-sheet {
        height: auto;
        bottom: auto;
        max-height: calc(100% - 1rem);
        width: 32rem;
        opacity: 0;
        transition: all 0.2s ease-out;
        top: $p;

        &--producer-sheet-position-middle,
        &--producer-sheet-position-open {
            opacity: 1;
            left: $w + 2 * $p;
        }

        &--product-sheet-position-open {
            left: $p;
        }
    }

    .product-sheet {
        width: 32rem;

        transition: all 0.2s ease-out;
        height: auto;
        bottom: auto;
        max-height: calc(100% - 1rem);
        opacity: 0;
        left: $p;

        &--producer-sheet-position-middle,
        &--producer-sheet-position-open {
            left: $w + 2 * $p;
            opacity: 0;
        }

        &--product-sheet-position-open {
            left: $w + 2 * $p;
            opacity: 1;
        }

        &--producer-sheet-position-closed {
            left: $p;
        }
    }

    .modal-sheet__sheet {
        &--slot-1,
        &--slot-2 {
            left: $w + 2 * $p;
        }
    }

}

@media screen and (min-width: 3 * $w + 5 * $p) {
    .list-sheet {
        left: $p;
    }

    .producer-sheet {
        &--producer-sheet-position-middle,
        &--producer-sheet-position-open {
            left: $w + 2 * $p;
        }
    }

    .product-sheet {
        &--producer-sheet-position-middle,
        &--producer-sheet-position-open {
            left: $w + 2 * $p;
            opacity: 0;
        }

        &--product-sheet-position-open {
            left: 2 * $w  + 3 * $p;
            opacity: 1;
            height: auto;
        }

        &--producer-sheet-position-closed {
            left: $p;
        }
    }

    .modal-sheet__sheet {
        &--slot-2 {
            left: 2 * $w  + 3 * $p;
        }
    }
}