@import '../index.scss';

.tag {
    color: $primary-text;
    border: 1px solid $primary-text;
    border-radius: 0.4rem;
    text-decoration: none;
    padding: 0rem 0.4rem;
    margin: 0;
    display: inline-flex;
    gap: $p2;
    height: 2rem;
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    box-sizing: border-box;
    background-color: $content-color;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    @include size-mins;

    &::before {
        content: ' ';
        display: block;
        background-color: $content-color;
        border-top: 2px solid $active;
        width: 0.6rem;
        height: 0.6rem;
        margin-right: 0.2rem;
        border-radius: 999px;
    }

    & > * {
        pointer-events: none;
    }

    &[role='button'] {
        @include clickable;
    }

    &--clickable {
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:hover {
            background-color: $active-shover;;
        }
    }

    &--partial {
        opacity: 0.4;
    }
}


.tag-editor {
    display: flex;
    align-items: center;
    gap: $p2;
    height: 3rem;
    align-items: center;
    background-color: $input-color;
    padding-right: $p2;

    input {
        height: 3rem;
    }
}

.popup-editor-button {
    cursor: pointer;
    background-color: $content-color;
    transition: all 0.2s ease-in-out;
    border: 0;
    width: 2rem;
    height: 2rem;

    &:hover {
        background-color: $active;
    }
}

.popup-editor-input {
    padding: 0 0.4rem;
    box-sizing: border-box;
    @include size-mins;
    border: 0;
    border-radius: 0;
    font-size: 0.9rem;
    flex-grow: 1;
}
.tag-select-list {
    display: flex;
    flex-direction: column;
    gap: 0.1rem;
    .popup-select__item {
        padding: 0.1rem;
    }
}

.tag-button--value {
    display: inline-flex;
    align-items: center;

    *:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    *:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: 0;
    }
}
