@import '../index.scss';

.product-view {
    &__content {
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: $p;
    }

    &__fields {
        @include card;
    }

    &__field {
        &__name {
            text-transform: uppercase;
            font-size: 0.8em;
            width: 7em;
            margin-right: 1rem;
            display: inline-block;
            color: grey;
        }
        padding: 0;
    }

    &__rating {
        @include card;

        &__header {
            display: flex;
            align-items: center;
            gap: $p;
        }

        &__number {
            color: grey;
        }

        p {
            padding-top: $p;
            margin: 0;
        }
    }

    &__buttons {
        padding: 0;
        display: flex;
        gap: $p2;
        flex-wrap: wrap;
    }
}
